@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply font-bold;
    }

    h2 {
        @apply font-bold;
    }

    a:hover, a:focus, a:target {
        text-decoration: underline;
    }

    a {
        cursor: pointer;
        font-weight: 500;
        text-align: start;
        user-select: text;

        @apply text-eui-primary;
    }

    input[type=email] {
        box-shadow: 0 0 #0000, inset 0 0 0 1px #ffffff1a;

        @apply rounded-md h-10 text-sm px-3 bg-eui-form-background;
    }

    button {
        font-weight: 500;
        @apply text-black text-sm px-3 rounded-md min-w-[112px] h-10;
    }

    button:hover {
        text-decoration: underline;
        transform: translateY(-1px);
        transition: transform 250ms ease-in-out 0s, background-color 250ms ease-in-out 0s;
    }
}

@layer components {
    .feature-checkmark {
        width: 20px;
        height: 20px;
        background-position: center;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill="%23d1d5db" fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" ></path></svg>');
    }

    .feature-negative-checkmark {
        width: 20px;
        height: 20px;
        background-position: center;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill="%23d1d5db" fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" ></path></svg>');
    }

    .pricing-plan-card {
        @apply rounded-3xl p-6 ring-1 ring-white/10 text-white;
    }

    .pricing-table-row {
        @apply bg-gray-800 text-sm;

        td, th {
            @apply px-2 py-2;
        }
    }

    .pricing-table-last-row {
        @apply border-none;
    }

    .pricing-table-feature-row {
        @apply border-b border-gray-700 hover:bg-gray-600 text-xxs;
    }

    @media screen(sm) {
        .pricing-table-row {
            td, th {
                @apply px-6 py-4 text-lg;
            }
        }

        .pricing-table-feature-row {
            td, th {
                @apply text-sm;
            }
        }
    }
}
